import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  handleChangeOrderStatus,
  handleGetOrders,
  handleChefPostReview,
} from "../../services/order";
import {
  handleOrderReviewAndRating,
  handleGetAllReview,
} from "../../services/order";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { handleGetDefaultSetting } from "../../services/default_setting";
import moment from "moment";
import BeforeFooter from "../components/homeWidget/BeforeFooter";

const UserOrder = () => {
  // const {chefId} = useParams();
  // const [chefID,setChefID] = useState(0);
  const { authToken } = useSelector((state) => state.user);
  const { userInfo } = useSelector((state) => state.user);
  const [defaultSettings, setDefaultSettings] = useState([]);
  // Refetch orders - when update status of order
  const [refetchOrder, setRefetchOrder] = useState(false);

  // const orderDetailInitial = {
  //     order_id: 1,
  //     dish_name: '',
  //     quantity: '',
  //     spice_level: '',
  //     portion_size: '',
  //     serving_size: '',
  // };
  const [isFetching, setIsFetching] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);

  // Pagination Start

  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page

  // Calculate the index of the first and last row for pagination
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = orderDetails.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(orderDetails.length / rowsPerPage); // Total number of pages

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Pagination End

  const handleStatusChange = (e, id) => {
    const newStatus = "canceled";
    const saveStatus = async () => {
      try {
        if (newStatus) {
          const ordersRetrieved = await handleChangeOrderStatus(
            authToken,
            { status: newStatus },
            id
          );
          console.log(ordersRetrieved);
          if (ordersRetrieved.success) {
            toast("Order Canceled!");
            setRefetchOrder((prevState) => !prevState);
          }
        }
        // setOrderDetails(ordersRetrieved);
      } catch (error) {
        console.log("Error While Updating Orders Status \n", error);
        toast.warn("Order Status Failed to Update!");
      }
    };
    saveStatus();
  };
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setIsFetching(true);
        const ordersRetrieved = await handleGetOrders(authToken, {
          user_id: userInfo.id,
        });
        console.log("user/shef orders", ordersRetrieved);

        // Flatten the nested order details into a single array of order details
        const mappedOrderDetails = ordersRetrieved.reduce((acc, order) => {
          const orderDetails = order.order_details.map((detail) => ({
            order_id: order.order_code,
            id: order.id,
            dish_name: detail.name,
            quantity: detail.quantity,
            spice_level: detail.user_menu.spice_level?.name,
            portion_size: detail.user_menu.portion_size,
            reviews: order?.reviews,
            created_at: order?.created_at,
            status: order?.status
              ? order?.status?.charAt(0).toUpperCase() + order?.status?.slice(1)
              : "",
            delivery_time: order?.delivery_time,
            totalPrice:
              (detail?.delivery_price +
                detail?.platform_price +
                detail?.unit_price) *
              detail.quantity,
            // serving_size: detail.user_menu.portion_type_id,//name is not available at this time  - removed
          }));
          return acc.concat(orderDetails);
        }, []);

        setOrderDetails(mappedOrderDetails);
      } catch (error) {
        console.log("Error While Fetching Orders \n", error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchOrders();
    console.log("useEffect is running userorder");
  }, [authToken, refetchOrder, userInfo.id]);

  useEffect(() => {
    const getDefaultSettings = async () => {
      try {
        const retrieveDefaultSettings = await handleGetDefaultSetting(
          authToken
        );
        setDefaultSettings(retrieveDefaultSettings);
      } catch (error) {
        console.log("Error While Fetching Order Settings \n", error);
      }
    };
    getDefaultSettings();
  }, [authToken]);

  const [error, setError] = useState({
    rating: "",
    review: "",
  });

  //--- reviews & rating
  const [reviewAndRating, setReviewAndRating] = useState({
    order_id: null,
    rating: 0,
    review: "",
    order_code: null,
    dish_name: null,
    created_at: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const onRequestClose = () => {
    setIsOpen(false);
    setReviewAndRating({
      order_id: null,
      review: "",
      rating: 0,
      order_code: null,
    });

    setError({
      rating: "",
      review: "",
    });
  };

  // Rating & review submit
  const ratingSubmit = async () => {
    const newErrors = {};
    if (!reviewAndRating.rating) newErrors.rating = "Please select a rating.";
    if (!reviewAndRating.review) newErrors.review = "Please enter your review.";

    setError(newErrors);

    if (Object.keys(newErrors).length > 0) {
      return;
    }

    try {
      const payload = {
        order_id: reviewAndRating.order_id,
        rating: reviewAndRating.rating,
        review: reviewAndRating.review,
      };
      console.log("Payload is ", payload);

      const respons = await handleOrderReviewAndRating(authToken, payload);
      toast.success("Rating Submitted");
      onRequestClose();
    } catch (error) {
      console.log("SOmething went wrong while rating order \n", error.message);
      toast.error(error.message || "Something went wrong while Rating");
    }
  };

  // Handle changes in Review
  const handleReviewChange = (e) => {
    setReviewAndRating((prev) => ({
      ...prev,
      review: e.target.value,
    }));

    // Clear review error when the user types
    if (error.review) {
      setError((prev) => ({
        ...prev,
        review: "", // Clear review error when typing
      }));
    }
  };

  // Handle changes in Rating
  const handleRatingChange = (rate) => {
    setReviewAndRating((prev) => ({
      ...prev,
      rating: rate,
    }));

    // Clear rating error when a rating is selected
    if (error.rating) {
      setError((prev) => ({
        ...prev,
        rating: "", // Clear rating error when selecting a rating
      }));
    }
  };

  const [reviews, setReviews] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  console.log(user)
  // console.log(user.last_order_address.chef_id)
  // var chefId = user.last_order_address.chef_id;
  var chefId = user.chef_id;

  useEffect(() => {
    (async () => {
      try {
        // console.log(chefId)
        const response = await handleGetAllReview(authToken, chefId);

        console.log("response of reviews", response.data);
        setReviews(response.data);
      } catch (error) {
        console.error(error);
        console.error("message", error.message);
        const message = error.message;
        if (message != "Request failed with status code 404") {
          toast.error(error);
        } else if (message === "Request failed with status code 404") {
          toast.error("chef isn't found in this city");
        }
      }
    })();
  }, [chefId]);

  // Function to toggle visibility of the reply section
  function toggleReplySection(index) {
    const replySection = document.getElementById(`reply-section-${index}`);
    const repliesContainer = document.getElementById(
      `replies-container-${index}`
    );

    // Check if reply form is currently hidden or not
    const isReplySectionVisible = !replySection.classList.contains("hidden");

    // Show or hide the reply section and replies container
    if (isReplySectionVisible) {
      replySection.classList.add("hidden");
      repliesContainer.classList.add("hidden");
    } else {
      replySection.classList.remove("hidden");
      repliesContainer.classList.remove("hidden");
    }
  }

  // Function to submit a reply
  const submitReply = async (index) => {
    const replyText = document.getElementById(`reply-text-${index}`).value;
    if (replyText.trim() === "") {
      alert("Please write a reply before submitting.");
      return;
    }

    // Create a new reply object (you would probably want to send this to the server via an API)
    const newReply = {
      text: replyText,
      created_at: new Date().toLocaleDateString(),
    };

    console.log(replyText, index);

    const respons = await handleChefPostReview(authToken, index, replyText);

    // Assuming we have access to the reviews data object, we can update the review with the new reply
    reviews[index].replies = reviews[index].replies || []; // Initialize the replies array if it doesn't exist
    reviews[index].replies.push(newReply);

    // Update the UI to display the new reply
    const repliesContainer = document.getElementById(
      `replies-container-${index}`
    );
    const newReplyDiv = document.createElement("div");
    newReplyDiv.classList.add("bg-gray-100", "p-4", "rounded-md", "my-2");
    newReplyDiv.innerHTML = `
    <p class="font-medium text-lg text-gray-900">Your Reply</p>
    <p class="text-gray-600 text-sm font-normal text-base leading-7 mb-4 lg:pr-8 break-words max-w-lg">${newReply.text}</p>
    <p class="text-gray-400 text-xs">${newReply.created_at}</p>
  `;

    repliesContainer.appendChild(newReplyDiv);

    // Clear the reply text area
    document.getElementById(`reply-text-${index}`).value = "";
  };

  return (
    <>
      <div className="">
        <Header />
        <div className="container mx-auto p-5">
          <div className="mt-6 p-5 bg-white rounded-xl border border-borderClr">
            <div>
              <h3 className="text-xl font-semibold leading-tight uppercase pt-2 mb-3 mt-6 border-b pb-2">
                Orders
              </h3>
              <div className="overflow-x-auto">
                <table className="text-left w-full menuTable border-0">
                  <thead>
                    <tr className="border-b">
                      <th className="w-[15%]">Order ID</th>
                      <th className="w-[20%] max-w-max">Dish Name</th>
                      <th className="w-[10%]">Delivery Date/Day/Time</th>
                      <th className="w-[10%]">Quantity</th>
                      <th className="w-[10%]">Spice Level</th>
                      <th className="w-[10%]">Portion Size</th>
                      <th className="w-[10%]">Status</th>
                      <th className="w-[10%]">Total Price</th>
                      {/* <th className='w-[15%]'>Serving Size</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr className='border-b'>
                                            <td>
                                                <p className='text-primaryGreen text-[14px]'>#ID03456</p>
                                            </td>
                                            <td>
                                                <Link to='/order-summary' className='text-[14px] mb-0 leading-tight font-semibold'>
                                                    Fish Cutlus
                                                </Link>
                                            </td>
                                            <td>
                                                <h4 className='text-[14px] mb-0 leading-tight'>x2</h4>
                                            </td>
                                            <td>
                                                <h4 className='text-[14px] mb-0 leading-tight'>Mild</h4>
                                            </td>
                                            <td>
                                                <h4 className='text-[14px] mb-0 leading-tight'>16 grams</h4>
                                            </td>
                                            <td>
                                                <h4 className='text-[14px] mb-0 leading-tight'>3 Serving</h4>
                                            </td>
                                        </tr> */}
                    {currentRows.map((detail, index) => (
                      <tr key={index} className="border-b">
                        <td>
                          <p className="text-primaryGreen text-[14px]">
                            #{detail.order_id}
                          </p>
                        </td>
                        <td>
                          <Link
                            to={
                              detail?.status?.toLowerCase() !== "canceled"
                                ? `/order-summary/${detail.id}`
                                : ""
                            }
                            className="text-[14px] mb-0 leading-tight font-semibold"
                          >
                            {detail.dish_name}
                          </Link>
                        </td>
                        {/* Delivery date time */}
                        <td>
                          <h4 className="text-[14px] mb-1 leading-tight">
                            -{" "}
                            {new Date(
                              detail.delivery_time
                            ).toLocaleDateString()}
                          </h4>
                          <h4 className="text-[14px] mb-1 leading-tight">
                            -{" "}
                            {new Date(detail.delivery_time).toLocaleDateString(
                              "en-US",
                              {
                                weekday: "long",
                              }
                            )}
                          </h4>
                          <h4 className="text-[14px] mb-1 leading-tight">
                            -{" "}
                            {new Date(
                              detail.delivery_time
                            ).toLocaleTimeString()}
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-[14px] mb-0 leading-tight">
                            x{detail.quantity}
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-[14px] mb-0 leading-tight">
                            {detail.spice_level}
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-[14px] mb-0 leading-tight">
                            {detail.portion_size}
                          </h4>
                        </td>

                        <td>
                          <h4
                            className={`${
                              detail?.status?.toLowerCase() === "canceled" &&
                              "text-primary"
                            } ${
                              detail?.status?.toLowerCase() === "accepted" &&
                              "text-orange-500"
                            } ${
                              detail?.status?.toLowerCase() === "delivered" &&
                              "text-green-700"
                            } text-[14px] mb-0 leading-tight`}
                          >
                            {detail.status}
                          </h4>
                        </td>
                        <td>
                          <h4 className="text-[14px] mb-0 leading-tight">
                            {detail.totalPrice}
                          </h4>
                        </td>
                        {detail?.reviews?.length < 1 && (
                          <td>
                            <button
                              disabled={
                                detail?.status?.toLowerCase() !== "delivered"
                              }
                              onClick={() => {
                                setReviewAndRating((prev) => {
                                  const deliveryDate = new Date(
                                    detail.delivery_time
                                  );
                                  return {
                                    ...prev,
                                    order_id: detail.id,
                                    order_code: detail.order_id,
                                    dish_name: detail.dish_name,
                                    created_at: `${deliveryDate.toLocaleDateString()} - ${deliveryDate.toLocaleDateString(
                                      "en-US",
                                      { weekday: "long" }
                                    )} - ${deliveryDate.toLocaleTimeString()}`,
                                  };
                                });
                                setIsOpen(true);
                              }}
                              className="text-[14px] text-primaryGreen hover:underline focus:underline mb-0 leading-tight disabled:text-[#ccc] disabled:cursor-not-allowed"
                            >
                              Add Rating
                            </button>
                          </td>
                        )}
                        <td>
                          {(() => {
                            const orderCreatedTime = moment(detail.created_at);
                            const currentTime = moment();
                            const timeSinceCreation = moment.duration(
                              currentTime.diff(orderCreatedTime)
                            );
                            const canCancel =
                              timeSinceCreation.asMinutes() <=
                              defaultSettings.cancellation_time_span;
                            return (
                              <button
                                disabled={
                                  !canCancel || detail?.status === "Canceled"
                                }
                                onClick={(e) =>
                                  handleStatusChange(e, detail.id)
                                }
                                // style={!canCancel ? { color: '#ccc', cursor: 'not-allowed' } : {}}
                                className="text-[14px] text-primary hover:underline focus:text mb-0 leading-tight disabled:text-[#ccc] disabled:cursor-not-allowed"
                              >
                                Cancel Order
                              </button>
                            );
                          })()}
                        </td>
                      </tr>
                    ))}
                    {/* Fetching orderr */}
                    {isFetching && (
                      <tr className="mt-2 font-semibold text-headGray">
                        <td colSpan={4}>Fetching Orders...</td>
                      </tr>
                    )}
                    {/* No orders  */}
                    {!isFetching && orderDetails?.length < 1 && (
                      <tr className="mt-2 font-semibold text-headGray">
                        <td colSpan={4}>No Orders</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {/* Pagination Controls */}
                <div className="flex justify-center mt-4">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-l"
                  >
                    Previous
                  </button>
                  {/* Page Numbers */}
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i}
                      onClick={() => handlePageChange(i + 1)}
                      className={`px-4 py-2 ${
                        currentPage === i + 1
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200"
                      } text-gray-700`}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-r"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="mt-6 p-5 bg-white rounded-xl border border-borderClr"> */}
          <section class="py-24 relative mt-6 p-5 bg-white rounded-xl border border-borderClr">
            <div class="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto">
              {/* <h2 class="font-manrope font-bold text-4xl text-black text-center mb-11">Review and Rating</h2> */}
              <div class="grid grid-cols-12 py-6 border-y border-gray-200 mb-11">
                <div class="col-span-12 lg:col-span-10 ">
                  <h5 class="font-manrope font-semibold text-2xl leading-9 text-black text-center">
                    Reviews
                    <span class="lg:hidden font-manrope font-semibold text-2xl leading-9 text-black text-center">
                      {" "}
                      & Rating
                    </span>
                  </h5>
                </div>
                <div class="col-span-12 lg:col-span-2 max-lg:hidden">
                  <h5 class="font-manrope font-semibold text-2xl leading-9 text-black text-center">
                    Rating
                  </h5>
                </div>
              </div>
              <div class="grid grid-cols-1 gap-8">
                {reviews.length > 0 ? (
                  reviews.map((review, index) => (
                    <>
                      <div
                        key={review.id}
                        class="grid grid-cols-12 max-w-sm sm:max-w-full mx-auto"
                      >
                        <div class="col-span-12 lg:col-span-10 ">
                          <div class="sm:flex gap-6">
                            <img
                              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                              alt="Robert image"
                              class="w-32 h-32 rounded-full object-cover"
                            />
                            <div class="text">
                              <p class="font-medium text-lg leading-8 text-gray-900 mb-2">
                                {review.user_menu.user.first_name +
                                  " " +
                                  review.user_menu.user.last_name}
                              </p>
                              <div class="flex lg:hidden items-center gap-2 lg:justify-between w-full mb-5">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_13624_2090)">
                                    <path
                                      d="M14.1033 2.56698C14.4701 1.82374 15.5299 1.82374 15.8967 2.56699L19.1757 9.21093C19.3214 9.50607 19.6029 9.71064 19.9287 9.75797L27.2607 10.8234C28.0809 10.9426 28.4084 11.9505 27.8149 12.5291L22.5094 17.7007C22.2737 17.9304 22.1662 18.2614 22.2218 18.5858L23.4743 25.8882C23.6144 26.7051 22.7569 27.3281 22.0233 26.9424L15.4653 23.4946C15.174 23.3415 14.826 23.3415 14.5347 23.4946L7.9767 26.9424C7.24307 27.3281 6.38563 26.7051 6.52574 25.8882L7.7782 18.5858C7.83384 18.2614 7.72629 17.9304 7.49061 17.7007L2.1851 12.5291C1.59159 11.9505 1.91909 10.9426 2.73931 10.8234L10.0713 9.75797C10.3971 9.71064 10.6786 9.50607 10.8243 9.21093L14.1033 2.56698Z"
                                      fill="#FBBF24"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_13624_2090">
                                      <rect
                                        width="30"
                                        height="30"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_13624_2090)">
                                    <path
                                      d="M14.1033 2.56698C14.4701 1.82374 15.5299 1.82374 15.8967 2.56699L19.1757 9.21093C19.3214 9.50607 19.6029 9.71064 19.9287 9.75797L27.2607 10.8234C28.0809 10.9426 28.4084 11.9505 27.8149 12.5291L22.5094 17.7007C22.2737 17.9304 22.1662 18.2614 22.2218 18.5858L23.4743 25.8882C23.6144 26.7051 22.7569 27.3281 22.0233 26.9424L15.4653 23.4946C15.174 23.3415 14.826 23.3415 14.5347 23.4946L7.9767 26.9424C7.24307 27.3281 6.38563 26.7051 6.52574 25.8882L7.7782 18.5858C7.83384 18.2614 7.72629 17.9304 7.49061 17.7007L2.1851 12.5291C1.59159 11.9505 1.91909 10.9426 2.73931 10.8234L10.0713 9.75797C10.3971 9.71064 10.6786 9.50607 10.8243 9.21093L14.1033 2.56698Z"
                                      fill="#FBBF24"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_13624_2090">
                                      <rect
                                        width="30"
                                        height="30"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_13624_2090)">
                                    <path
                                      d="M14.1033 2.56698C14.4701 1.82374 15.5299 1.82374 15.8967 2.56699L19.1757 9.21093C19.3214 9.50607 19.6029 9.71064 19.9287 9.75797L27.2607 10.8234C28.0809 10.9426 28.4084 11.9505 27.8149 12.5291L22.5094 17.7007C22.2737 17.9304 22.1662 18.2614 22.2218 18.5858L23.4743 25.8882C23.6144 26.7051 22.7569 27.3281 22.0233 26.9424L15.4653 23.4946C15.174 23.3415 14.826 23.3415 14.5347 23.4946L7.9767 26.9424C7.24307 27.3281 6.38563 26.7051 6.52574 25.8882L7.7782 18.5858C7.83384 18.2614 7.72629 17.9304 7.49061 17.7007L2.1851 12.5291C1.59159 11.9505 1.91909 10.9426 2.73931 10.8234L10.0713 9.75797C10.3971 9.71064 10.6786 9.50607 10.8243 9.21093L14.1033 2.56698Z"
                                      fill="#FBBF24"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_13624_2090">
                                      <rect
                                        width="30"
                                        height="30"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_13624_2090)">
                                    <path
                                      d="M14.1033 2.56698C14.4701 1.82374 15.5299 1.82374 15.8967 2.56699L19.1757 9.21093C19.3214 9.50607 19.6029 9.71064 19.9287 9.75797L27.2607 10.8234C28.0809 10.9426 28.4084 11.9505 27.8149 12.5291L22.5094 17.7007C22.2737 17.9304 22.1662 18.2614 22.2218 18.5858L23.4743 25.8882C23.6144 26.7051 22.7569 27.3281 22.0233 26.9424L15.4653 23.4946C15.174 23.3415 14.826 23.3415 14.5347 23.4946L7.9767 26.9424C7.24307 27.3281 6.38563 26.7051 6.52574 25.8882L7.7782 18.5858C7.83384 18.2614 7.72629 17.9304 7.49061 17.7007L2.1851 12.5291C1.59159 11.9505 1.91909 10.9426 2.73931 10.8234L10.0713 9.75797C10.3971 9.71064 10.6786 9.50607 10.8243 9.21093L14.1033 2.56698Z"
                                      fill="#FBBF24"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_13624_2090">
                                      <rect
                                        width="30"
                                        height="30"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_13624_2090)">
                                    <path
                                      d="M14.1033 2.56698C14.4701 1.82374 15.5299 1.82374 15.8967 2.56699L19.1757 9.21093C19.3214 9.50607 19.6029 9.71064 19.9287 9.75797L27.2607 10.8234C28.0809 10.9426 28.4084 11.9505 27.8149 12.5291L22.5094 17.7007C22.2737 17.9304 22.1662 18.2614 22.2218 18.5858L23.4743 25.8882C23.6144 26.7051 22.7569 27.3281 22.0233 26.9424L15.4653 23.4946C15.174 23.3415 14.826 23.3415 14.5347 23.4946L7.9767 26.9424C7.24307 27.3281 6.38563 26.7051 6.52574 25.8882L7.7782 18.5858C7.83384 18.2614 7.72629 17.9304 7.49061 17.7007L2.1851 12.5291C1.59159 11.9505 1.91909 10.9426 2.73931 10.8234L10.0713 9.75797C10.3971 9.71064 10.6786 9.50607 10.8243 9.21093L14.1033 2.56698Z"
                                      fill="#FBBF24"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_13624_2090">
                                      <rect
                                        width="30"
                                        height="30"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <p class="font-normal text-base leading-7 text-gray-400 mb-4 lg:pr-8 break-words max-w-lg">
                                {review.review}
                              </p>

                              <div class="flex items-center justify-between">
                                <div class="cursor-pointers flex items-center gap-2">
                                  <a
                                    href="javascript:;"
                                    className="font-semibold text-lg text-indigo-600"
                                    onClick={() => toggleReplySection(index)}
                                  >
                                    View & Reply
                                  </a>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                  >
                                    <path
                                      d="M8.25324 5.49609L13.7535 10.9963L8.25 16.4998"
                                      stroke="#4F46E5"
                                      stroke-width="1.6"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </div>
                                {/* <p className="font-medium text-sm leading-7 text-gray-400 lg:text-center whitespace-nowrap">
                      {new Date(review.created_at).toLocaleDateString()}
                    </p> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-span-12 lg:col-span-2 max-lg:hidden flex lg:items-center flex-row lg:flex-col justify-center max-lg:pt-6"
                          key={index}
                        >
                          <div className="flex items-center gap-2 lg:justify-between w-full mb-5">
                            {/* Render stars based on the rating */}
                            {[...Array(5)].map((_, i) => (
                              <svg
                                key={i}
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                viewBox="0 0 30 30"
                                fill="none"
                              >
                                <g clipPath="url(#clip0)">
                                  <path
                                    d="M14.1033 2.56698C14.4701 1.82374 15.5299 1.82374 15.8967 2.56699L19.1757 9.21093C19.3214 9.50607 19.6029 9.71064 19.9287 9.75797L27.2607 10.8234C28.0809 10.9426 28.4084 11.9505 27.8149 12.5291L22.5094 17.7007C22.2737 17.9304 22.1662 18.2614 22.2218 18.5858L23.4743 25.8882C23.6144 26.7051 22.7569 27.3281 22.0233 26.9424L15.4653 23.4946C15.174 23.3415 14.826 23.3415 14.5347 23.4946L7.9767 26.9424C7.24307 27.3281 6.38563 26.7051 6.52574 25.8882L7.7782 18.5858C7.83384 18.2614 7.72629 17.9304 7.49061 17.7007L2.1851 12.5291C1.59159 11.9505 1.91909 10.9426 2.73931 10.8234L10.0713 9.75797C10.3971 9.71064 10.6786 9.50607 10.8243 9.21093L14.1033 2.56698Z"
                                    fill={
                                      i < review.rating ? "#FBBF24" : "#E4E4E7"
                                    } // Yellow for rated stars, light gray for others
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0">
                                    <rect width="30" height="30" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            ))}
                          </div>
                          <p className="font-medium text-lg leading-8 text-gray-400 lg:text-center whitespace-nowrap">
                            {new Date(review.created_at).toLocaleDateString()}{" "}
                            {/* Format the date */}
                          </p>
                          {/* <p className="text-gray-600 text-base mt-2">{review.review}</p> */}
                        </div>
                      </div>

                      <div id={`replies-container-${index}`} className="mt-2 ">
                        {review.replies && review.replies.length > 0
                          ? review.replies.map((reply, replyIndex) => (
                              <div
                                key={replyIndex}
                                className="bg-gray-100 p-4 rounded-md my-2"
                              >
                                <p className="font-medium text-lg text-gray-900">
                                  Reply
                                </p>
                                <p className="text-gray-600 text-sm font-normal text-base leading-7 mb-4 lg:pr-8 break-words max-w-lg">
                                  {reply.text}
                                </p>

                                {/* Use flex to align the date to the right */}
                                <div className="flex justify-between items-center mt-2">
                                  {/* This will push the date to the right side */}
                                  <p className="text-gray-400 text-xs ml-auto">
                                    {new Date(
                                      reply.created_at
                                    ).toLocaleDateString()}
                                  </p>
                                </div>
                              </div>
                            ))
                          : null}

                        {/* Show the "No replies yet" message only if there are no replies */}
                        {/* {(!review.replies || review.replies.length === 0) && (
            <p className="text-gray-500">No replies yet.</p>
          )} */}
                      </div>

                      {/* Reply Section */}
                      <div
                        id={`reply-section-${index}`}
                        className="hidden mt-4"
                      >
                        <textarea
                          id={`reply-text-${index}`}
                          className="w-full p-2 border border-gray-300 rounded-md"
                          placeholder="Write your reply..."
                        ></textarea>
                        <button
                          className="mt-2 px-4 py-2 bg-indigo-600 text-white rounded-md"
                          onClick={() => submitReply(index)}
                        >
                          Submit Reply
                        </button>
                      </div>

                      <div class="pb-8 border-b border-gray-100 w-full"></div>
                    </>
                  ))
                ) : (
                  <div class="text-center mt-16">No reviews available</div>
                )}
              </div>
            </div>
          </section>

          {/* </div> */}
          {/* <div className='mt-6 p-5 bg-white rounded-xl border border-borderClr'>
                        <div >
                            <h3 className='text-xl font-semibold leading-tight uppercase mb-3 border-b pb-2'>Refunded Order (0)</h3>
                            <div className='overflow-x-auto'>
                                <table className="text-left w-full menuTable border-0">
                                    <thead>
                                        <tr className='border-b'>
                                            <th className='w-[15%]'>Order ID</th>
                                            <th className='w-[20%]'>Qty / Dish</th>
                                            <th className='w-[10%]'>Original Earnings</th>
                                            <th className='w-[20%]'>Fault</th>
                                            <th className='w-[15%]'>Earnings after refund</th>
                                            <th className='w-[20%]'>Reason</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='border-b'>
                                            <td>
                                                <p className='text-primaryGreen text-[14px]'>#ID03456</p>
                                            </td>
                                            <td>
                                                <h4 className='text-[14px] mb-0 leading-tight'>Fish Cutlus</h4>
                                                <h4 className='text-[14px] mb-0 leading-tight'>x2</h4>
                                            </td>
                                            <td>
                                                <h4 className='text-[14px] mb-0 leading-tight'>$20.99</h4>
                                            </td>
                                            <td>
                                                <h4 className='text-[14px] mb-0 leading-tight'>To much Heating</h4>
                                            </td>
                                            <td>
                                                <h4 className='text-[14px] mb-0 leading-tight'>15.50</h4>
                                            </td>
                                            <td>
                                                <h4 className='text-[14px] mb-0 leading-tight'>Mistake on your part. We will deduct based on what the mistake was.</h4>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div > */}
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Reviews"
        style={{
          content: {
            height: "auto",
            top: "20%",
            minWidth: "max-content",
            maxWidth: "500px",
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
      >
        <div className="flex flex-col  h-ful">
          {/* Modal content here */}
          <div className="flex items-center justify-between border-b pb-3 gap-3">
            <h2 className="text-lg font-semibold leading-tight mb-0">
              Order Review & Rating
            </h2>
            <button onClick={onRequestClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="rgba(0,0,0,1)"
              >
                <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
              </svg>
            </button>
          </div>

          <div>
            {/* Order Code  */}
            <p className="my-4 text-primaryGreen ">
              <strong>Order Code :</strong> #{reviewAndRating.order_code}
            </p>
            {/* Dish Name */}
            <p className="my-4 text-primaryGreen">
              <strong>Dish Name :</strong> {reviewAndRating.dish_name}
            </p>
            {/* Date */}
            <p className="my-4 text-primaryGreen">
              <strong>Delivery Date/Day/Time :</strong>{" "}
              {reviewAndRating.created_at}
            </p>
            <h3 className="mb-3 text-lg text-gray my-2 font-semibold">
              Add Reviews
            </h3>
            <textarea
              className="py-2 min-h-20"
              placeholder="Write Review Here"
              maxLength={500}
              value={reviewAndRating.review}
              onChange={handleReviewChange}
            />
            {/* Display error for review */}
            {error.review && (
              <div className="text-red-500 text-sm mt-1">{error.review}</div>
            )}

            {/* Rating */}
            <div className="mt-3 mb-5">
              <h5 className="my-1 text-lg text-gray font-semibold">
                Rating : ({reviewAndRating.rating})
              </h5>
              <div className="flex gap-1 mt-2">
                {[...Array(5)].map((_, index) => {
                  const currentRate = index + 1;
                  return (
                    <label key={currentRate}>
                      <input
                        type="radio"
                        name="rating"
                        className="hidden"
                        value={currentRate}
                        onClick={() => handleRatingChange(currentRate)}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18"
                        fill={
                          currentRate <= reviewAndRating.rating
                            ? "#FF9529"
                            : "none"
                        }
                      >
                        <path
                          stroke={
                            currentRate <= reviewAndRating.rating ? "" : "gray"
                          }
                          strokeWidth={1}
                          d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"
                        ></path>
                      </svg>
                    </label>
                  );
                })}
              </div>
            </div>

            {/* Display error for rating */}
            {error.rating && (
              <div className="text-red-500 text-sm mt-1">
                {error.rating === 0 ? "" : error.rating}
              </div>
            )}

            <button
              onClick={ratingSubmit}
              className="my-3 w-max bg-primary text-white text-lg uppercase px-6 py-2 font-semibold rounded-lg disabled:opacity-60"
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <BeforeFooter />
    </>
  );
};

export default UserOrder;
