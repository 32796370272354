import React from 'react'
import ProfileForm from '../components/profileWidgets/ProfileForm';
import ProfileDetail from '../components/profileWidgets/ProfileDetail';
import Header from '../components/Header';
import BeforeFooter from '../../frontend/components/homeWidget/BeforeFooter';

export const Profile = () => {

    return (
        <>
            <div className=''>
                <Header />
                <div className='container mx-auto p-5'>
                    <ProfileDetail />
                    <ProfileForm />
                </div>
            </div>
            <BeforeFooter />
        </>
    )
}
export default Profile