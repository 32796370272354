import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import moment from "moment";
import BeforeFooter from '../../frontend/components/homeWidget/BeforeFooter';
import { useSelector } from "react-redux";
  import {handleChefPostReview,handleGetAllReview} from '../../services/order';
  import { toast } from "react-toastify";
  import Modal from "react-modal";

export const OrderReview = () => {

    const { authToken } = useSelector((state) => state.user);
    
    const [reviews, setReviews] = useState([]);
    // Pagination Start 
  
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  
    // Calculate the index of the first and last row for pagination
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = reviews.slice(indexOfFirstRow, indexOfLastRow);
  
    const totalPages = Math.ceil(reviews.length / rowsPerPage); // Total number of pages
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
    // Pagination End


    
    const user = JSON.parse( localStorage.getItem('user') )
    // console.log(user.last_order_address.chef_id)
    // console.log(user)
    var chefId = user.id;
  
    useEffect(()=> { 
      (async()=>{
          try { 
  
            // console.log(chefId)
              const response = await handleGetAllReview(
                  authToken,
                   chefId
                );
  
              console.log("response of reviews", response.data);
              setReviews(response.data)
  
          } catch(error) {
              console.error(error)
              console.error("message", error.message)
              const message = error.message;
              if(message != "Request failed with status code 404"){
                  toast.error(error)
              }
              else if(message === "Request failed with status code 404") {
                  toast.error("chef isn't found in this city")
                  
              }
                  
          }
      }) ()
  }, [chefId])
  
  
// Function to format the date and time
function formatDate(dateString) {
    const date = new Date(dateString);

    // Format: "Weekday, Month Day, Year, HH:mm:ss AM/PM"
    return date.toLocaleString('en-US', {
        weekday: 'short',  // Optional: day of the week (Mon, Tue, etc.)
        year: 'numeric',
        month: 'short',    // Month in abbreviated form (Jan, Feb, Mar, etc.)
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,      // Use 12-hour clock with AM/PM
    });
}

const [isOpen, setIsOpen] = useState(false);

const [error, setError] = useState({
    review: ""
  });

  const [reviewReply, setReviewReply] = useState({
    review: "",
  });
  

 // Handle changes in Review
 const handleReviewChange = (e) => {
    setReviewReply((prev) => ({
      ...prev,
      review: e.target.value,
    }));

    // Clear review error when the user types
    if (error.review) {
      setError((prev) => ({
        ...prev,
        review: "", // Clear review error when typing
      }));
    }
  };

  const onRequestClose = () => {
    setIsOpen(false);
    setReviewReply({
      review: ""
    });

    setError({
    review: ""
    });
  };

  const replySubmit = async () => {
    const newErrors = {};
      if (!reviewReply.review) newErrors.review = 'Please enter your reply.';
    
      setError(newErrors);
    
      if (Object.keys(newErrors).length > 0) {
        return;
      }

      try {
  
        const respons = await handleChefPostReview(
            authToken,
             4,
            reviewReply.review
          );

        toast.success("Reply Submitted");
        onRequestClose();
      } catch (error) {
        console.log("Something went wrong while reply review \n", error.message);
        toast.error(error.message || "Something went wrong while Reply");
      }
    }

    return (
        <>
            <div className=''>
                <Header />
                <div className='container mx-auto p-5'>
                    <div className='mt-6 p-5 bg-white rounded-xl border border-borderClr'>
                        <div className=''>
                            <h3 className='text-xl font-semibold leading-tight border-b pt-2 pb-3 mb-0'>Order Review</h3>
                        </div>
                        <div className='overflow-x-auto w-full'>
                            <table className="text-left w-full menuTable border-0">
                                <thead>
                                    <tr className='border-b'>
                                        <th className='w-[15%]' >Order Code</th>
                                        <th className='w-[15%]'>Dish Name</th>
                                        <th className='w-[40%]'>Review</th>
                                        <th className='w-[15%]'>Created Date</th>
                                        <th className='w-[15%]'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {currentRows.map((detail, index) => (
                                  
                                    <tr  key={index} className='border-b'>
                                        <td>
                                            <h4 className='text-[14px] mb-1 leading-tight'>{detail.order?.order_code}</h4>
                                        </td>
                                        <td>
                                            <h4 className='text-[14px] mb-0 leading-tight'>{detail.user_menu?.name}</h4>
                                            {/* <p className='text-primaryGreen text-[14px]'>#ID03456</p> */}
                                        </td>
                                        <td style={{ maxWidth: '300px' }}>
                        {/* Truncate review text to 50 characters */}
                        <h4 className="text-[14px] mb-0 leading-tight" 
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%'  // Ensure it takes full width of the cell
                            }}>
                            {/* Truncate text to 50 characters in JSX */}
                            {detail.review.length > 50 ? `${detail.review.substring(0, 50)}...` : detail.review}
                        </h4>
                    </td>


                                        {/* <td>
                                            <select id="selectOption">
                                                <option value="">Approve</option>
                                                <option value="option1">Hold</option>
                                            </select>
                                        </td> */}
                                          <td>
                                            <h4 className='text-[14px] mb-1 leading-tight'> {formatDate(detail.created_at)}</h4>
                                        </td>
                                        <td>
                                            <button className='bg-primaryDark py-1 px-4 rounded-md text-white text-base font-semibold'
                                             onClick={() => {
                                                setIsOpen(true);
                                              }}
                                            >Reply</button>
                                        </td>
                                    </tr>
                                  
                                    ))}
                                </tbody>
                            </table>

                            {/* Pagination Controls */}
<div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-l"
        >
          Previous
        </button>
        {/* Page Numbers */}
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i + 1)}
            className={`px-4 py-2 ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'} text-gray-700`}
          >
            {i + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded-r"
        >
          Next
        </button>
      </div>
      
                        </div>
                    </div >


            

                </div >
            </div >


            <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Reviews"
        style={{
          content: {
            height: "45%",
            top: "20%",
            minWidth: "max-content",
            maxWidth: "500px",
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
      >
        <div className="flex flex-col  h-ful">
          {/* Modal content here */}
          <div className="flex items-center justify-between border-b pb-3 gap-3">
            <h2 className="text-lg font-semibold leading-tight mb-0">
              Order Review Reply
            </h2>
            <button onClick={onRequestClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="rgba(0,0,0,1)"
              >
                <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
              </svg>
            </button>
          </div>

          <div>

      
       
             <h3 className="mb-3 text-lg text-gray my-2 font-semibold">Add Reply</h3>
    <textarea
      className="py-2 min-h-20"
      placeholder="Write Reply Here"
      maxLength={500}
      value={reviewReply.review}
        onChange={handleReviewChange}
    />
    {/* Display error for review */}
    {error.review && (
      <div className="text-red-500 text-sm mt-1">{error.review}</div>
    )}
    
  

    <button
      onClick={replySubmit}
      className="my-3 w-max bg-primary text-white text-lg uppercase px-6 py-2 font-semibold rounded-lg disabled:opacity-60"
    >
      Submit
    </button>
        </div>
        </div>
      </Modal>
      <BeforeFooter />
        </>
    )
}
export default OrderReview