import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BeforeFooter from "../components/homeWidget/BeforeFooter";
import ChefContent from "../components/chefDetailWidgets/ChefContent";
import FilterAndDate from "../components/chefDetailWidgets/FilterAndDate";
import { handleGetChefWithDishes } from "../../services/get_without_auth";
import { handleChefPostReview, handleGetAllReview } from "../../services/order";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export const ShefDetailPage = () => {
  const { authToken } = useSelector((state) => state.user);
  const { chefId } = useParams();
  const [chefAndDishes, setChefAndDishes] = useState([]);
  // Get chef with dishes
  useEffect(() => {
    (async () => {
      try {
        const city = JSON.parse(localStorage.getItem("region"));
        const response = await handleGetChefWithDishes(chefId, city.id);
        console.log("response of chef with dishes ", response);
        setChefAndDishes(response);
      } catch (error) {
        console.error(error);
        console.error("message", error.message);
        const message = error.message;
        if (message != "Request failed with status code 404") {
          toast.error(error);
        } else if (message === "Request failed with status code 404") {
          toast.error("chef isn't found in this city");
        }
      }
    })();
  }, [chefId]);
  // console.log(chefAndDishes)

  // State to hold the array of feedbacks (messages)
  const [feedbackList, setFeedbackList] = useState([]);
  // State to hold the input field value (single message)
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const [reviews, setReviews] = useState([]);
  // const [reviews, setReviews] = useState([
  //     { userName: 'John Doe', message: 'This is great!' },
  //     { userName: 'Jane Smith', message: 'Nice job!' },
  // ]);

  // Handle input change (for the message input field)
  const handleInputChange = (event) => {
    setFeedbackMessage(event.target.value);
  };

  // Handle form submission (posting feedback)
  const handlePostFeedback = async (event) => {
    event.preventDefault(); // Prevent the form from refreshing the page

    if (feedbackMessage.trim()) {
      // Add the new feedback as an object to the feedback list
      setFeedbackList((prevFeedbackList) => [
        ...prevFeedbackList,
        { message: feedbackMessage }, // Store feedback as an object with a 'message' key
      ]);

      console.log(feedbackMessage);
      const respons = await handleChefPostReview(
        authToken,
        chefId,
        feedbackMessage
      );

      setFeedbackMessage(""); // Clear the input field after posting feedback
    } else {
      alert("Please type some feedback.");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await handleGetAllReview(authToken, chefId);

        console.log("response of reviews", response.data);
        setReviews(response.data);
      } catch (error) {
        console.error(error);
        console.error("message", error.message);
        const message = error.message;
        if (message != "Request failed with status code 404") {
          toast.error(error);
        } else if (message === "Request failed with status code 404") {
          toast.error("chef isn't found in this city");
        }
      }
    })();
  }, [chefId]);

  return (
    <>
      <Header />

      <div className="shefDetail_page">
        <ChefContent chefAndDishes={chefAndDishes} />
        <FilterAndDate chefAndDishes={chefAndDishes} />

        <div className="w-[78rem] mx-auto p-6">
          <h2 className="md:text-3xl text-2xl uppercase font-bold -tracking-wider mb-6">
            REVIEWS
          </h2>
          <div className="h-[300px] flex flex-col">
            <div className="bg-gray-100 flex-1 overflow-y-auto rounded-lg shadow-md scrollbar-thin scrollbar-thumb-[#f02444] scrollbar-track-[#f0f0f0]">
              <div className="px-4 py-2">
                {reviews.length > 0 ? (
                  reviews.map((review, index) => (
                    <div key={index} className="mb-4">
                      <div className="bg-white rounded-lg p-4 shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out border border-gray-200 space-y-2">
                        <p className="text-gray-800 text-base font-medium break-words line-clamp-3">
                          {review.review || "No message provided"}
                          {review.review?.length > 100 && (
                            <button className="text-blue-500 ml-2 text-sm">
                              Read More
                            </button>
                          )}
                        </p>
                        {review.user_menu?.user?.first_name && (
                          <p className="text-gray-600 text-sm mt-1">
                            Reviewed by:{" "}
                            <span className="font-semibold">
                              {review?.user_menu?.user?.first_name +
                                " " +
                                review?.user_menu?.user?.last_name}
                            </span>
                          </p>
                        )}
                        <div className="inline-flex items-center gap-x-2 px-2 py-1 rounded-md bg-[#ffc00047] w-max">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="12"
                            height="12"
                            fill="#323232"
                          >
                            <path d="M12.0006 18.26L4.94715 22.2082L6.52248 14.2799L0.587891 8.7918L8.61493 7.84006L12.0006 0.5L15.3862 7.84006L23.4132 8.7918L17.4787 14.2799L19.054 22.2082L12.0006 18.26Z"></path>
                          </svg>
                          <h4 className="text-xs mb-0 font-semibold">
                            {review?.user_menu?.average_rating
                              ? parseFloat(
                                  review?.user_menu?.average_rating
                                ).toFixed(1)
                              : 0}{" "}
                            <span className="text-[12px] font-normal">
                              ({review?.user_menu?.total_reviews})
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center mt-16 text-gray-500">
                    <p className="text-xl font-semibold mb-2">
                      No Reviews Available
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
            </div>
      </div>
      <BeforeFooter />
    </>
  );
};
export default ShefDetailPage;
